<script>
export default {
    name: 'monitor'
}
</script>

<template>
    <div>monitor</div>
</template>

<style scoped lang="scss">

</style>
